<template>
  <CRow>
    <CCol lg="12">
      <CCard>
        <ValidationObserver ref="form">
          <CCardHeader tag="div">
            <CIcon name="cil-grid"/>
            Chi tiết ngành lĩnh vực kêu gọi đầu tư
          </CCardHeader>
          <CCardBody>
            <CForm>
              <CRow>
                <CCol lg="6">
                  <ValidationProvider name="Tên ngành/lĩnh vực" rules="required" v-slot="{ errors, valid , touched }">
                    <BaseInput disabled label="Tên ngành/lĩnh vực" horizontal :value.sync="detailItem.tenNganhLinhVuc" style="background-color: white"
                               :is-valid="valid"
                               :touched="touched" :invalid-feedback="errors"/>
                  </ValidationProvider>
                </CCol>
                <CCol lg="6">
                  <ValidationProvider name="Mã ngành ISIC2" rules="required" v-slot="{ errors, valid , touched }">
                    <BaseInput disabled label="Mã ngành ISIC2" horizontal :value.sync="detailItem.maNganhISIC" :is-valid="valid"  style="background-color: white"
                               :touched="touched" :invalid-feedback="errors"/>
                  </ValidationProvider>
                </CCol>
              </CRow>
              <CRow>
                <CCol lg="6">
                  <CTextarea disabled label="Mô tả" horizontal :value.sync="detailItem.moTa" style="background-color: white"/>
                </CCol>
              </CRow>
              <CRow>
                <CCol lg="6">
                  <CRow>
                    <CCol tag="label" sm="3" class="col-form-label">Trạng thái</CCol>
                    <CCol sm="9" class="form-inline">
                      <CInputCheckbox disabled label="Hoạt động" :checked.sync="detailItem.trangThai" style="background-color: white"/>
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
            </CForm>
          </CCardBody>

          <CCardFooter tag="div" class="text-right">
            <CButton color="secondary" class="mr-2" @click="back">
              <CIcon name="cil-arrow-left"/>
              Quay lại
            </CButton>
            <CButton color="primary" class="mr-2" @click="editItem">
              <CIcon name="cil-pencil"/>
              Sửa
            </CButton>
            <CButton color="danger" @click="isDeleteConfirmationModalOpen = true">
              <CIcon name="cil-x"/>
              Xóa
            </CButton>
          </CCardFooter>
        </ValidationObserver>
      </CCard>
    </CCol>
    <DeleteConfirmationModal
      title="Xóa quốc gia"
      :show.sync="isDeleteConfirmationModalOpen"
      :is-deleting="isDeleting"
      :item="detailItem" @confirm-destroy="confirmDestroy"/>
  </CRow>
</template>

<script>
import { statuses, loaiDonVis } from '@/shared/appConstants'
import { mapGetters, mapActions } from 'vuex'
import {
  DELETE_NGANHLINHVUCKEUGOIDAUTU,
  GET_NGANHLINHVUCKEUGOIDAUTU
} from '@/store/modules/nganh-linh-vuc-keu-goi-dau-tu/actionTypes'

export default {
  name: 'NganhLinhVucKeuGoiDauTuDetail',
  data () {
    return {
      optionsTrangThai: statuses,
      optionsLoaiDonVi: loaiDonVis,
      isDeleteConfirmationModalOpen: false
    }
  },
  computed: {
    ...mapGetters('nganhLinhVucKeuGoiDauTu', {
      detailItem: 'detailItem',
      isFetchingSingle: 'isFetchingSingle',
      isDeleting: 'isDeleting',
      responseResult: 'responseResult'
    })
  },
  methods: {
    ...mapActions('nganhLinhVucKeuGoiDauTu', {
      getDonVi: GET_NGANHLINHVUCKEUGOIDAUTU,
      deleteItem: DELETE_NGANHLINHVUCKEUGOIDAUTU
    }),
    back () {
      this.$router.push({ path: '/danh-muc/nganh-linh-vuc-keu-goi-dau-tu' })
    },
    editItem () {
      this.$router.push({ path: `/danh-muc/nganh-linh-vuc-keu-goi-dau-tu/${this.detailItem.id}/cap-nhat` })
    },
    async confirmDestroy () {
      await this.deleteItem(this.detailItem)
      if (this.responseResult.error) {
        this.$toast.error(this.responseResult.message)
      } else {
        this.$toast.success(this.responseResult.message)
        this.isDeleteConfirmationModalOpen = false
        await this.$router.push({ path: '/danh-muc/nganh-linh-vuc-keu-goi-dau-tu' })
      }
    }
  },
  async created () {
    await this.getDonVi(this.$route.params.id)
  }
}
</script>

<style scoped>

</style>
